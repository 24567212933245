<template>
	<div id="Lucky">
		<div id="Lucky-box">
			<div class="Title Title-title">
				<p>追梦宝箱</p>
			</div>

			<div class="Lucky-out">
				<div class="Lucky-top">
					<div class="Lucky-top-item" v-for="(item,index) in outlist" :key="index"
						 :class="{'active':index == outidx}" @click="outSelect(index,item.id)">
						<i></i>
						<img :src="item.cover" alt="">
						<div>
							{{item.name}}
						</div>
					</div>
				</div>
<!--				<div class="smll-list">-->
<!--					<div class="smll-list-title">武器的类型</div>-->
<!--					<div class="smll-list-box">-->
<!--						<div class="smll-list-item" v-for="(item,index) in smlllist" :key="index" :class="{'active' : index == smllidx}" @click="smllSelect(index)">{{item.name}}</div>-->
<!--					</div>-->
<!--				</div>-->
			</div>
			<div class="Lucky-list-box">
				<div class="search">
					<input type="text" placeholder="请输入饰品关键字" maxlength='60' v-model="SoName">
					<div class="btn" @click="GetSoList"></div>
				</div>

				<div class="Lucky-list-out">
					<van-loading class="vant-load" v-show="loadingShow == true" color="#000000" size="60" />
					<div class="Lucky-list" v-show="loadingShow == false">
						<div class="Lucky-list-item"
							 :style="`background-image:url(${require('@/assets/images/NavHeader/LV' + item.lv + '.png')})`"
							 v-for="(item,index) in equipmentlist" :key="index" @click="ToOpen(item.id)">
							<div class="ceng"></div>
							<div class="top">
								<p><img src="@/assets/images/PublicImg/JinBi.png" alt="">{{item.bean}}</p>
								<span>{{item.dura_alias}}</span>
							</div>

							<div class="pic">
								<img :src="item.cover" alt="">
							</div>
							<div class="name">{{item.name | GuoLvOne}}</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	import { luckyType, luckyList, luckySo } from '@/network/api.js'
	export default {
		name: 'Lucky',
		data() {
			return {
				outidx: 0,
				smllidx:0,

				loadingShow:true,

				outlist:[],
				SoName:'',

				smlllist:[
					{
						name:'全部'
					},
					{
						name:'雪猎手套'
					},
					{
						name:'驾驶手套'
					},
					{
						name:'手部手套'
					},
					{
						name:'摩托手套'
					},
					{
						name:'专业手套'
					},
					{
						name:'运动手套'
					},
					{
						name:'九头蛇手套'
					}
				],

				equipmentlist:[]
			}
		},

		filters:{
			GuoLvOne(str){
				return str.length > 15 ? str.substr(0,15) + '...' : str
			}
		},

		created() {
			this.GetluckyType()
			this.GetluckyList('1')
		},

		methods:{
			//装备类型
			GetluckyType(){
				luckyType().then((res) => {
					// console.log(res.data)
					this.outlist = res.data.data
				})
			},

			//主分类选中
			outSelect(index,id){
				this.outidx = index
				// console.log(id)
				this.GetluckyList(id)
			},


			//装备列表
			GetluckyList(id){
				this.loadingShow = true
				luckyList(id).then((res) => {
					// console.log(res.data.data)
					this.equipmentlist = res.data.data

				})
			},

			//搜索
			GetSoList(){
				this.loadingShow = true
				luckySo(this.SoName).then((res) => {
					// console.log(res.data.data)
					this.equipmentlist = res.data.data
				})
			},

			//路由跳转开箱
			ToOpen(id){
				this.$router.push({
					path:'/Lucky_open',
					query:{
						id:id
					}
				})
			},

			//副分类选中
			smllSelect(index){
				this.smllidx = index
			}
		},
		watch:{
			equipmentlist(val){
				this.loadingShow = false
			}
		}
	}
</script>
<style lang="scss">
#Lucky-box {
     width: 14rem;
     padding-top: 0.4rem;
     .Lucky-out {
         margin-top: 0.4rem;
         width: 100%;
         /*box-shadow: 0 24px 31px rgba(15,16,31,.71);*/
         background: url(../../assets/images/jinkuang.png);
		 background-size: cover;
         /*border-radius: 0.04rem 0.04rem 0.1rem 0.1rem;*/
         .Lucky-top {
             width: 100%;
             height: 2rem;
             /*padding: 0 0.4rem;*/
             display: flex;

             align-items: center;
             justify-content: space-between;
             box-sizing: border-box;
             .Lucky-top-item {
                 width: 2rem;
                 height: 2rem;
				 padding: 0 0.3rem;
                 display: flex;
				 flex-direction: column;
                 justify-content: center;
                 align-items: center;
                 position: relative;
                 cursor: pointer;
				 /*background-color: #191C34;*/
                 img {
                     max-width: 100%;
                     max-height: 100%;
					 margin-bottom: .1rem;
                     /*filter:grayscale(1);*/
                }
                 i {
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     width: 0.02rem;
                     content: "";
                     height: 0.02rem;
                     border-radius: 50%;
                     background: none;
                     box-shadow: none;
                }
                 &.active {
					 background-image: url("../../assets/images/Lucky/topNav.png");
					 background-size: 100% 100%;
                    /* img {*/
                    /*     filter: none*/
                    /*}*/
                    /* i {*/
                    /*     background: rgba(67,42,192,.3);*/
                    /*     box-shadow:0 0 0.55rem 0.4rem #ffd300;*/
                    /*}*/
                }
            }
        }
         .smll-list{
             width: 100%;
             display: flex;
             align-items: center;
             padding: 0.2rem 0.5rem;
             box-sizing: border-box;
             .smll-list-title{
                 width: 0.8rem;
                 height: 0.4rem;
                 font-size: 0.16rem;
                 color: #8e908c;
                 line-height: 0.4rem;
                 margin-right: 0.2rem;
            }
             .smll-list-box{
                 flex: 1;
                 display: flex;
                 align-items: center;
                 flex-wrap: wrap;
                 .smll-list-item{
                     height: 0.4rem;
                     padding: 0 0.15rem;
                     border-radius: 0.04rem;

                     border: 0.01rem solid #e00000;


                     border: 1px solid #e00000;
                     box-sizing: border-box;
                     line-height: 0.4rem;
                     text-align: center;
                     font-size: 0.14rem;
                     margin-right: 0.15rem;
                     color: #e00000;
                     cursor: pointer;
                     &.active{
                         background: #e00000;
                         color: #fff;
                    }
                }
            }
        }
    }
     .Lucky-list-box{
         margin-top: 0.4rem;
         width: 100%;
         border-radius: 0.04rem 0.04rem 0.1rem 0.1rem;
         .search{
             width: 100%;
             height: 0.7rem;
             position: relative;
             input{
                 width: 100%;
                 height: 100%;
                 border: none;
                 background: #1B1D36;
				 /*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
				 /*background-size: 100%;*/
                 padding: 0 1rem 0 0.2rem;
				 border: 1px solid #1B1D36;
                 box-sizing: border-box;
                 font-size: 0.22rem;
				 border-radius: 0.04rem;
				 overflow: hidden;
				 &:focus {
				 	border: 1px solid #ffd322;
				 }
            }
             .btn{
                 width: 1rem;
                 height: 0.7rem;
                 position: absolute;
                 right: 0;
                 top: 0;
                 background:#ffd322;
                 border-radius: 0.04rem;
				 cursor: pointer;
				 background-image:url(../../assets/images/PublicImg/serch.png);
				 background-repeat: no-repeat;
				 background-position: center;
				 background-size: 40%;
            }
        }
		.Lucky-list-out{
			width: 100%;
			min-height: 4.5rem;
			background-size: cover;
			.vant-load{
				text-align: center;
				padding-top: 1.6rem;
			}
			.Lucky-list{
			     width: 100%;
			     display: flex;
			     flex-wrap: wrap;
			     .Lucky-list-item{
			         width: 2.2rem;
			         margin-top: 0.2rem;
					 padding: 0.1rem 0.05rem;
			         cursor: pointer;
			         /*border: 1px solid transparent;*/
			         box-sizing: border-box;
			         transition: .2s;
					 background-color:#131426 ;
					 /*background: url(../../assets/images/public/back_pub.jpg) no-repeat center;*/
					 background-size: 100% 100%;
					 margin-right: 0.16rem;
					 border-radius: 0.04rem;
					 overflow: hidden;
					 position: relative;
					 &:nth-child(6n){
						 margin-right: 0;
					 }
					 .ceng{
						 position: absolute;
						 width: 100%;
						 height: 100%;
						 left: 0;
						 top: 0;
						 background: rgba(233, 177, 14, 0.1);
						 z-index: 2;
						 opacity: 0;
					 }
					 .top{
						 width: 100%;
						 height: 0.3rem;
						 display: flex;
						 align-items: center;
						 justify-content: space-between;
						 padding: 0 0.2rem;
						 box-sizing: border-box;
						 p{
							 width: 50%;
							 height: 0.3rem;
						      text-align: right;
						      font-size: 0.14rem;
						      color: #75dc9e;
							  display: flex;
							  align-items: center;
						 	 img{
						 		 width: 0.24rem;
						 	 }
						 }
						 span{
							 font-size: 0.14rem;
							 color: #e08902;
						 }
					 }

			         .pic{
			             width: 1.5rem;
			             height: 1.5rem;
			             margin: 0 auto;
			             display: flex;
			             justify-content: space-between;
			             align-items: center;
			             img{
			                 max-width: 100%;
			                 max-height: 100%;
			            }
			        }
			         .name{
			             text-align: center;
			             line-height: 1.75em;
			             font-size: 0.12rem;
			             padding: 0.1rem 0.2rem;
			             box-sizing: border-box;
			        }
			         &:hover{
			             /*border-color: #E9B10E;*/
						 background-image: url("../../assets/images/hover.png") !important;
						 background-size: 100% 100%;
						 .ceng{
							 opacity: 0;
						 }
			        }
			    }
			}
		}


    }
}
 </style>
